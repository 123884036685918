import { useQuery } from "react-query";
import { HashLink } from "react-router-hash-link";
import { fetchData } from "./api/request";

type FooterType = {
  color: string;
  icon?: string;
  logo?: string;
};

export default function Footer({ color, icon, logo }: FooterType) {
  const { data } = useQuery("dataFooter", fetchData);

  return (
    <section
      className={`w-full h-fit pb-20 flex flex-wrap xl:flex-row justify-start sm:justify-center gap-8 px-2 sm:px-0 text-base-100 pt-8 sm:pt-16 mt-16 sm:mt-32  ${color} relative`}
    >
      <img
        src={icon}
        alt="Tiere"
        className="h-14 sm:h-24 absolute -top-12 sm:-top-20 sm:left-16"
      />
      <div className="w-40 h-full relative hidden md:block">
        <img
          src={logo}
          alt="Schullogo"
          className="w-full max-h-36 h-fit absolute sm:-top-12 -right-4 sm:-right-8"
        />
      </div>
      {data &&
        data?.element?.map(
          (item: {
            headline: string;
            Post: string;
            Mail: string;
            Telefon: string;
          }) => (
            <FooterElement
              key={item.headline}
              headline={item.headline}
              post={item.Post}
              mail={item.Mail}
              tel={item.Telefon}
            />
          )
        )}
      {/* <FooterElement /> */}
      <div className="flex flex-row justify-center sm:justify-none w-full sm:w-fit absolute bottom-4 sm:right-64">
        <HashLink to="/impressum#_" className="font-bold pr-4">
          Impressum
        </HashLink>
        |
        <HashLink to="/datenschutz#_" className="font-bold px-4">
          Datenschutz
        </HashLink>
        |
        <HashLink to="/kontakt" className="font-bold pl-4">
          Kontakt
        </HashLink>
      </div>
    </section>
  );
}

type FooterElementType = {
  headline: string;
  post: string;
  mail: string;
  tel: string;
};

function FooterElement({ headline, post, mail, tel }: FooterElementType) {
  return (
    <div className="flex flex-col text-base-100 px-2 text-sm">
      <p className="font-bold">{headline}</p>
      <div className="flex flex-row">
        <div className="flex flex-col pr-2 leading-5 font-medium">
          <p>Post</p>
          <p>Tel.</p>
          <p>Mail</p>
        </div>
        <div className="flex flex-col leading-5">
          <p>{post}</p>
          <p>{tel}</p>
          <p className="underline">
            <a href={"mailto:" + mail}>{mail}</a>
          </p>
        </div>
      </div>
    </div>
  );
}
