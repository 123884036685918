import React from "react";
import { API } from "../../../utils/constants";

type ContactSocialWorkerType = {
  item: {
    name: string;
    number: string;
    mail: string;
    handy_number: string;
  };
  url: string;
};

export default function ContactSocialWorker({
  item,
  url,
}: ContactSocialWorkerType) {
  return (
    <div className="flex flex-col w-full items-center sm:items-end pt-16">
      <div
        style={{ backgroundImage: `url(${API.img + url})` }}
        className="h-64 w-56 bg-cover bg-center flex justify-end items-end"
      >
        <p className="w-full bg-black/30 text-base-100 font-medium p-1 h-fit">
          {item?.name}
        </p>
      </div>
      {/* <img src={API.img + url} alt='contact img' className='w-56 h-64'/> */}
      <div className="flex flex-col w-56 pt-2">
        <p>
          <span className="font-semibold pr-2">Telefon:</span>
          {item?.number}
        </p>
        <p>
          <span className="font-semibold pr-2">Handy-Nr.:</span>
          {item?.handy_number}
        </p>
        <p>
          <span className="font-semibold pr-2">Mail:</span>
          <a className="underline text-blue" href={"mailto:" + item?.mail}>
            {item?.mail}
          </a>
        </p>
      </div>
    </div>
  );
}
