import { useState } from "react";
import BasePage from "../../components/layout/BasePage";
import BaseHeroSmall from "../../components/layout/BaseHeroSmall";
import BaseTextSection from "../../components/layout/BaseTextSection";
import ContactSocialWorker from "./components/ContactSocialWorker";
import BaseAccordion from "../../components/layout/BaseAccordion";
import ConsultingTeacher from "./components/ConsultingTeacher";
import BaseViewPort from "../../components/layout/BaseViewPort";
import ConsultingBigAccordion from "./components/ConsultingBigAccordion";
import AnimalLineImg from "../../components/common/AnimalLineImg";
import FleckLeftImg from "../../components/common/FleckLeftImg";
import { useQuery } from "react-query";
import {
  fetchContact1,
  fetchContact2,
  fetchContact3,
  fetchData,
} from "./api/request";
import Loading from "../../components/common/Loading";
import NotFound from "../../components/common/NotFound";
import BaseLayout from "../../components/layout/BaseLayout";

export default function ConsultingPage() {
  const [acc0, setAcc0] = useState(false);
  const [acc1, setAcc1] = useState(false);
  const [acc2, setAcc2] = useState(false);
  const [acc3, setAcc3] = useState(false);

  const { data, error, isLoading } = useQuery("dataConsulting", fetchData);
  const {
    data: contact1,
    error: errContact1,
    isLoading: loadingContact1,
  } = useQuery("dataConsultingContact1", fetchContact1);
  const {
    data: contact2,
    error: errContact2,
    isLoading: loadingContact2,
  } = useQuery("dataConsultingContact2", fetchContact2);
  const {
    data: contact3,
    error: errContact3,
    isLoading: loadingContact3,
  } = useQuery("dataConsultingContact3", fetchContact3);

  return (
    <BaseLayout title={data?.seo?.metaTitle ?? ''} description={data?.seo?.metaDescription ?? ''} keywords={data?.seo?.keywords ?? ''} url={data?.seo?.canonicalURL ?? null}>
      <BasePage color="bg-green" icon="/png/footer/Element Tiere grün.png" logo="png/footer/Logo_Beratung.png">
        {(isLoading || loadingContact1 || loadingContact2 || loadingContact3) && (
          <Loading />
        )}
        {(error || errContact1 || errContact2 || errContact3) && <NotFound />}
        {data && contact1 && contact2 && contact3 && (
          <>
            <BaseHeroSmall
              headline={"Beratung"}
              sectioncolor={"bg-green"}
              icons="png/weiss/Elemente_Tiere Beratung.png"
              padding="-bottom-[1.7rem]"
            />
            <BaseViewPort>
              <BaseTextSection
                headline={data?.headline1}
                color="text-green"
                content={data?.absatz1}
              />
            </BaseViewPort>
            <AnimalLineImg right />
            <BaseViewPort>
              <BaseTextSection
                headline={data?.headline2}
                color="text-green"
                content={data?.absatz2}
              />
              <ConsultingBigAccordion
                bool={acc0}
                func={() => setAcc0(!acc0)}
                headline={data?.dropdown1_headline}
                text1={data?.dropdown1_text}
                text2={data?.dropdown1_text2}
              />
            </BaseViewPort>
            <FleckLeftImg paddingTop="top-16" />
            <BaseViewPort>
              <ContactSocialWorker item={data?.contact1} url={contact1} />
              <BaseTextSection
                headline={data?.headline3}
                color="text-green"
                content={data?.absatz3}
              />
              <BaseAccordion
                color="green"
                bool={acc1}
                func={() => setAcc1(!acc1)}
                columns
                headline={data?.dropdown2_headline}
                text={data?.dropdown2_text}
              />
              <ConsultingTeacher
                name1={data?.contact2?.name}
                mail1={data?.contact2?.mail}
                url1={contact2}
                name2={data?.contact3?.name}
                mail2={data?.contact3?.mail}
                url2={contact3}
              />
              <BaseTextSection
                headline={data?.headline4}
                color="text-green"
                content={data?.absatz4}
              />
              <BaseAccordion
                file={data?.file}
                color="green"
                bool={acc2}
                func={() => setAcc2(!acc2)}
                columns
                headline={data?.dropdown3_headline}
                text={data?.dropdown3_text}
                text2={data?.dropdown3_text2}
                button={true}
              />
              <BaseAccordion
                color="green"
                bool={acc3}
                func={() => setAcc3(!acc3)}
                headline={data?.dropdown4_headline}
                text={data?.dropdown4_text}
              />
            </BaseViewPort>
          </>
        )}
      </BasePage>
    </BaseLayout>
  );
}
